<template>
    <v-container>
        <v-alert :type="alertType" v-model="alertDisplay" dismissible>{{alertMessage}}</v-alert>

        <v-row>
            <v-col sm="3">
                <v-btn class="text-xs-leftt" color="primary" depressed @click="openCreateUser">
                    Create user
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-data-table class="elevation-1"
                              :headers="headers"
                              :items="users"
                              :item-class="itemRowClass"
                              :sort-by="['Email']"
                              :items-per-page="15"
                              :footer-props="{'items-per-page-options':[15, 25, 50, 100, -1]}"
                              :search="search"
                              @click:row="handleRowClick"
                              :loading="loading"
                              loading-text="Loading... Please wait">
                    <template v-slot:items="props">
                        <td class="text-xs-right">{{ props.item.id }}</td>
                        <td class="text-xs-right">{{ props.item.firstName }}</td>
                        <td class="text-xs-right">{{ props.item.lastName }}</td>
                        <td class="text-xs-right">{{ props.item.username }}</td>
                        <td class="text-xs-right">{{ props.item.email }}</td>
                        <td class="text-xs-right">{{ props.item.roleId }}</td>
                        <td class="text-xs-right">{{ props.item.roleId }}</td>
                        <td class="text-xs-right">{{ props.item.isEnabled }}</td>
                    </template>
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                              v-slot:[`item.${header.value}`]="{ header, value }">
                        {{ header.formatter(value) }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog v-model="userDialog" max-width="1200" persistent @keydown.esc="userDialog = false">
            <v-card v-if="activeUser">
                <v-form ref="formUser">
                    <v-card-title class="headline grey lighten-2 py-2" primary-title>
                        <v-flex v-if="activeUser.id != null" xs6>
                            Edit User {{ activeUser.username }}
                        </v-flex>
                        <v-flex v-if="activeUser.id == null" xs6>
                            Create User
                        </v-flex>
                        <v-flex xs6 class="text-xs-right">
                            <v-btn @click="userDialog = false" icon>
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-alert :type="alertTypeUser" v-model="alertDisplayUser" dismissible>{{alertMessageUser}}</v-alert>
                    <v-divider></v-divider>
                    <v-layout class="px-3 mb-2">
                        <v-flex xs6>
                            <v-flex disabled label="User ID" :value="activeUser.id"></v-flex>
                            <v-flex>
                                <v-text-field class="required pa-1" style="margin-top:24px" label="Username" v-model="activeUser.username"
                                              counter="256"
                                              :rules="[ rules.required, rules.usernameCharacters, () => !!activeUser.username && activeUser.username.length <= 256 || 'Max 256 characters']"
                                              maxlength="256">
                                </v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-text-field class="required pa-1" label="Email" v-model="activeUser.email"
                                              counter="256"
                                              :rules="[rules.required, rules.email, () => !!activeUser.email && activeUser.email.length <= 256 || 'Max 256 characters']"
                                              maxlength="256">
                                </v-text-field>
                            </v-flex>
                            <v-flex v-if="activeUser.id != null">
                                <v-text-field class="pa-1" :type="showPassword ? 'text' : 'password'" label="Password" v-model="activeUser.password"
                                              :rules="[ rules.password ]"
                                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword">
                                </v-text-field>
                            </v-flex>
                            <v-flex v-if="activeUser.id == null">
                                <v-text-field class="required pa-1" :type="showPassword ? 'text' : 'password'" label="Password" v-model="activeUser.password"
                                              :rules="[ rules.required, rules.password ]"
                                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword">
                                </v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-text-field class="required pa-1" label="First Name" v-model="activeUser.firstName"
                                              counter="128"
                                              :rules="[ rules.required, rules.nameCharacters, () => !!activeUser.firstName && activeUser.firstName.length <= 128 || 'Max 128 characters']"
                                              maxlength="128">
                                </v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-text-field class="required pa-1" label="Last Name" v-model="activeUser.lastName"
                                              counter="128"
                                              :rules="[ rules.required, rules.nameCharacters, () => !!activeUser.lastName && activeUser.lastName.length <= 128 || 'Max 128 characters']"
                                              maxlength="128">
                                </v-text-field>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex>
                                <v-select required class="small-select required pa-1"
                                          v-model="activeUser.roleId"
                                          :items="roles"
                                          item-text="name"
                                          item-value="id"
                                          label="Role" dense>
                                </v-select>
                            </v-flex>
                        </v-flex>
                    </v-layout>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn v-if="activeUser.id != null" color="success" depressed @click="updateUser" :disabled="updateUserButtonDisabled">
                            Update
                        </v-btn>
                        <v-btn v-if="activeUser.id == null" color="success" depressed @click="createUser" :disabled="createUserButtonDisabled">
                            Create
                        </v-btn>
                        <v-btn v-if="activeUser.isEnabled && activeUser.id != null" class="danger" depressed @click="disableUser" :disabled="disableUserButtonDisabled">
                            Disable
                        </v-btn>
                        <v-btn v-if="!activeUser.isEnabled && activeUser.id != null" color="success" depressed @click="enableUser" :disabled="enableUserButtonDisabled">
                            Enable
                        </v-btn>
                        <v-btn v-if="activeUser.id != null" color="primary" depressed @click="openUserAgentsDialog" :disabled="userAgentsButtonDisabled">
                            Agents
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" depressed @click="closeUserDialog" :disabled="closeUserButtonDisabled">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="userAgentsDialog" max-width="1200" persistent @keydown.esc="userAgentsDialog = false">
            <v-card v-if="userAgents">
                <v-form ref="formUserAgents">
                    <v-card-title class="headline grey lighten-2 py-2" primary-title>
                        <v-flex xs6>
                            Edit {{ activeUser.username }} agents list
                        </v-flex>
                        <v-flex xs6 class="text-xs-right">
                            <v-btn @click="userAgentsDialog = false" icon>
                                <v-icon>fa-times</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-alert :type="alertTypeUserAgents" v-model="alertDisplayUserAgents" dismissible>{{alertMessageUserAgents}}</v-alert>
                    <v-divider></v-divider>
                    <v-layout class="px-3 mb-2">
                        <v-flex>
                            <v-data-table v-model="selectedAgents"
                                          :headers="agentsHeaders"
                                          :items="userAgents"
                                          item-key="agentId"
                                          hide-default-footer
                                          disable-pagination
                                          show-select
                                          @click:row="handleAgentClick"
                                          class="elevation-1">
                            </v-data-table>
                        </v-flex>
                    </v-layout>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="success" depressed @click="updateUserAgents" :disabled="updateUserAgentsButtonDisabled">
                            Update
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" depressed @click="closeUserAgentsDialog" :disabled="closeUserAgentsButtonDisabled">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>
    import axios from "axios";

    export default {
        name: "admin",
        components: {},
        data() {
            return {
                alertType: null,
                alertMessage: null,
                alertDisplay: false,
                alertTypeUser: null,
                alertMessageUser: null,
                alertDisplayUser: false,
                alertTypeUserAgents: null,
                alertMessageUserAgents: null,
                alertDisplayUserAgents: false,
                search: "",
                loading: true,
                users: [],
                userAgents: null,
                selectedAgents: [],
                userDialog: false,
                userAgentsDialog: false,
                activeUser: null,
                useVolumeFilter: true,
                useExpirationFilter: true,
                userDialogHasErrors: false,
                showPassword: false,
                updateUserAgentsButtonDisabled: false,
                userAgentsButtonDisabled: false,
                createUserButtonDisabled: false,
                updateUserButtonDisabled: false,
                disableUserButtonDisabled: false,
                enableUserButtonDisabled: false,
                closeUserAgentsButtonDisabled: false,
                closeUserButtonDisabled: false,
                totalItems: 0,
                currentPage: 1,
                options: {},
                roles: [{ id: 1, name: "Admin" }, { id: 2, name: "User" }],
                dateFormat: "YYYY-MM-DD",
                rules: {
                    required: value => !!value || 'Required',
                    usernameCharacters: value => {
                        const pattern = /^[a-z0-9.]+$/
                        return !value || (value && pattern.test(value)) || 'Username can contain lowercase letters, numbers and dots'
                    },
                    nameCharacters: value => {
                        const pattern = /^[A-Za-z .-]+$/
                        return !value || (value && pattern.test(value)) || 'Field can contain letters, spaces, dashes and dots'
                    },
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail'
                    },
                    password: value => {
                        const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                        return !value || (value && pattern.test(value)) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter'
                    },
                    requiredSelect: [(v) => v.length > 0 || 'Required']
                },
                agentsHeaders:
                    [
                        {
                            text: "AgentId",
                            value: "agentId",
                            filterable: false,
                            sortable: false,
                            align: ' d-none',
                            width: '0%'
                        },
                        {
                            text: "Name",
                            value: "agentName",
                            filterable: true,
                            sortable: true,
                            align: "left",
                            width: '70%'
                        }
                    ],
                headers: [
                    {
                        text: "Id",
                        value: "id",
                        filterable: false,
                        sortable: false,
                        align: ' d-none',
                        width: '0%'
                    }, {
                        text: "First Name",
                        value: "firstName",
                        filterable: true,
                        sortable: true,
                        align: "left",
                        width: '20%'
                    },
                    {
                        text: "Last Name",
                        value: "lastName",
                        filterable: true,
                        sortable: true,
                        align: "left",
                        width: '20%'
                    },
                    {
                        text: "Username",
                        value: "username",
                        filterable: true,
                        sortable: true,
                        align: 'end',
                        width: '20%'
                    },
                    {
                        text: "Email",
                        value: "email",
                        filterable: true,
                        sortable: true,
                        align: 'end',
                        width: '20%'
                    },
                    {
                        text: "Role",
                        value: "roleName",
                        filterable: true,
                        sortable: true,
                        align: 'end',
                        width: '10%'
                    },
                    {
                        text: "Role",
                        value: "roleId",
                        filterable: false,
                        sortable: false,
                        align: ' d-none',
                        width: '0%'
                    },
                    {
                        text: "Enabled",
                        value: "isEnabled",
                        filterable: true,
                        sortable: true,
                        align: 'end',
                        width: '10%',
                        formatter: (x) => (x ? 'Yes' : 'No')
                    }
                ],
            }
        },
        watch: {

        },

        created() {
            this.fetchUsers();
        },
        computed: {
            userDialogData() {
                return {
                    username: this.activeUser.username,
                    email: this.activeUser.email,
                    firstName: this.activeUser.firstName,
                    lastName: this.activeUser.lastName,
                    roleId: this.activeUser.roleId
                }
            }
        },

        methods: {
            showAlert(type, message) {
                this.alertType = type;
                this.alertMessage = message;
                this.alertDisplay = true;
            },
            showUserAlert(type, message) {
                this.alertTypeUser = type;
                this.alertMessageUser = message;
                this.alertDisplayUser = true;
            },
            showUserAgentsAlert(type, message) {
                this.alertTypeUserAgents = type;
                this.alertMessageUserAgents = message;
                this.alertDisplayUserAgents = true;
            },
            setButtonsDisabledValue(value) {
                this.updateUserButtonDisabled = value;
                this.createUserButtonDisabled = value;
                this.enableUserButtonDisabled = value;
                this.disableUserButtonDisabled = value;
                this.updateUserAgentsButtonDisabled = value;
                this.closeUserAgentsButtonDisabled = value;
                this.closeUserButtonDisabled = value;
                this.userAgentsButtonDisabled = value;
            },
            handleAgentClick(value) {
                console.error(value);
            },
            handleRowClick(row) {
                this.alertDisplay = false;
                this.setButtonsDisabledValue(false);
                this.activeUser = { id: row.id, firstName: row.firstName, lastName: row.lastName, username: row.username, email: row.email, isEnabled: row.isEnabled, roleId: row.roleId};
                this.userDialog = true;
                this.alertDisplay = false;
            },
            openCreateUser() {
                this.alertDisplay = false;
                this.setButtonsDisabledValue(false);
                this.activeUser = { id: null, firstName: null, lastName: null, username: null, email: null, password: null, roleId: 2};
                this.userDialog = true;
                this.alertDisplay = false;
            },
            openUserAgentsDialog() {
                this.alertDisplayUserAgents = false;
                this.updateUserAgentsButtonDisabled = false;
                this.closeUserAgentsButtonDisabled = false;
                this.fetchUserAgents(this.activeUser.id);
                this.userAgentsDialog = true;
                this.alertDisplayUser = false;
            },
            closeUserAgentsDialog() {
                this.userAgentsDialog = false;
                this.alertDisplayUserAgents = false;
                this.selectedAgents = [];
                this.userAgents = null;
            },
            closeUserDialog() {
                this.$refs.formUser.reset();
                this.userDialog = false;
                this.alertDisplayUser = false;
            },
            updateUserAgents() {
                this.setButtonsDisabledValue(true);

                let agentIds = this.selectedAgents.map(x => x.agentId);
                axios.post(`Users/UpdateUserAgentsList`,
                    {
                        UserId: this.activeUser.id,
                        AgentIds: agentIds
                    })
                    .then(() => {
                        this.showUserAlert("success", "List of user agents updated");
                        this.setButtonsDisabledValue(false);
                        this.userAgentsDialog = false;
                        this.selectedAgents = [];
                        this.userAgents = null;
                    }).catch((err) => {
                        console.log(err);
                        this.showUserAgentsAlert("error", "An error occured while updating the list of user agents!");
                        this.setButtonsDisabledValue(false);
                    }).finally(() => {
                    });
            },
            updateUser() {

                if (this.$refs.formUser.validate()) {

                    this.userDialogHasErrors = false;
                    this.setButtonsDisabledValue(true);

                    var data = {
                        Id: this.activeUser.id,
                        FirstName: this.activeUser.firstName.trim(),
                        LastName: this.activeUser.lastName.trim(),
                        Username: this.activeUser.username.trim(),
                        Email: this.activeUser.email.trim(),
                        RoleId: this.activeUser.roleId
                    };

                    if (this.activeUser.password) {
                        data.Password = this.activeUser.password;
                    }

                    axios.post(`Users/Update`, data)
                        .then(() => {
                            this.showAlert("success", "User updated");
                            this.setButtonsDisabledValue(false);
                            this.$refs.formUser.reset();
                            this.activeUser = null;
                            this.userDialog = false;
                            this.fetchUsers();
                        }).catch((err) => {
                            console.log(err);
                            this.showUserAlert("error", "An error occured while updating the user!");
                            this.setButtonsDisabledValue(false);
                        }).finally(() => {
                        });
                }
            },
            createUser() {
                if (this.$refs.formUser.validate()) {

                    this.setButtonsDisabledValue(true);

                    axios.post(`Users/Create`,
                        {
                            FirstName: this.activeUser.firstName.trim(),
                            LastName: this.activeUser.lastName.trim(),
                            Username: this.activeUser.username.trim(),
                            Password: this.activeUser.password,
                            Email: this.activeUser.email.trim(),
                            RoleId: this.activeUser.roleId
                        })
                        .then(() => {
                            this.showAlert("success", "User created");
                            this.setButtonsDisabledValue(false);
                            this.$refs.formUser.reset();
                            this.activeUser = null;
                            this.userDialog = false;
                            this.fetchUsers();
                        }).catch((err) => {
                            console.log(err);
                            this.showUserAlert("error", "An error occured while creating the user!");
                            this.setButtonsDisabledValue(false);
                        }).finally(() => {
                        });
                }
            },
            disableUser() {
                this.setButtonsDisabledValue(true);

                axios.post(`Users/DisableUser`, { Id: this.activeUser.id})
                    .then(() => {
                        this.showAlert("success", "User disabled");
                        this.setButtonsDisabledValue(false);
                        this.activeUser = null;
                        this.userDialog = false;
                        this.fetchUsers();
                    }).catch((err) => {
                        console.log(err);
                        this.showUserAlert("error", "An error occured while disabling the user!");
                        this.setButtonsDisabledValue(false);
                    }).finally(() => {
                    });

            },
            enableUser() {
                this.setButtonsDisabledValue(true);

                axios.post(`Users/EnableUser`, { Id: this.activeUser.id })
                    .then(() => {
                        this.showAlert("success", "User enabled");
                        this.setButtonsDisabledValue(false);
                        this.activeUser = null;
                        this.userDialog = false;
                        this.fetchUsers();
                    }).catch((err) => {
                        console.log(err);
                        this.showUserAlert("error", "An error occured while enabling the user!");
                        this.setButtonsDisabledValue(false);
                    }).finally(() => {
                });
            },
            itemRowClass: function (item) {
                var className = "";
                if (!item.isEnabled) {
                    className = "dandelion-cell"
                }
                return className;
            },

            fetchUsers() {
                this.getUsersFromApi()
                    .then(data => {
                        data.items.forEach(item => {
                            if (item.roleId == 1) {
                                item.roleName = "Admin";
                            }
                            else {
                                item.roleName = "User";
                            }
                        });
                        this.users = data.items;
                });
            },
            fetchUserAgents(userId) {
                this.getUserAgents(userId)
                    .then(data => {
                        this.userAgents = data.items;
                        data.items.forEach(item => {
                            if (item.isSelected == 1) {
                                this.selectedAgents.push(item);
                            }
                        });
                    });
            },

            getUsersFromApi() {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    axios.get(`Users/GetAll`)
                        .then((result) => {
                            let items = result.data;
                            resolve({
                                items
                            });
                        }).catch((err) => {
                            console.log(err);
                            this.showAlert("error", "An error occured while getting the list of users!");
                            reject(err);
                        }).finally(() => {
                            this.loading = false;
                        });
                })
            },
            getUserAgents(userId) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    axios.get(`Users/GetUserAgents/${userId}`)
                        .then((result) => {
                            let items = result.data;
                            resolve({
                                items
                            });
                        }).catch((err) => {
                            console.log(err);
                            this.showUserAlert("error", "An error occured while getting the list of user agents!");
                            this.userAgentsDialog = false;
                            reject(err);
                        }).finally(() => {
                            this.loading = false;
                        });
                })
            }
        }
    };
</script>

<style>
    .dandelion-cell {
        background-color: #ffeaab !important;
    }

    .pink-cell {
        background-color: #ffc7ce !important;
    }

    .light-green-cell {
        background-color: #d1f2d7 !important;
    }

    .danger {
        background-color: #dc3545 !important;
    }

    .required label::after {
        content: "*";
    }
</style>